<template>
  <b-modal header-class="ion-bg-modal-header" size="xl"
           centered scrollable no-close-on-backdrop
           v-model="visible">

    <template #modal-header>
      <div class="fw-bold">{{ $t('notice.noticeTitle') }} {{ $t('common.create') }}</div>
    </template>

    <div class="row">
      <div class="col-xl-12">

        <div class="row mb-3">
          <div class="col-xl-4">
            <label class="form-label" for="notice-create-system-type">
              <span>{{ $t('notice.appCsmsType') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <multiselect id="notice-create-system-type"
                         v-model="form.system"
                         label="text" track-by="value"
                         :options="systemTypeOptions"
                         selectLabel=""
                         selectedLabel=""
                         deselectLabel=""
                         :searchable="false" :allow-empty="false" />
          </div>
          <div class="col-xl-4">
            <label class="form-label" for="notice-create-type">
              <span>{{ $t('notice.noticeType') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <div class="btn-group w-100" role="group">
              <input type="radio" class="btn-check" id="typeBtn1" autocomplete="off"
                     v-model="form.noticeType" value="General" />
              <label class="btn btn-outline-secondary" for="typeBtn1">{{ $t('common.general') }}</label>
              <input type="radio" class="btn-check" id="typeBtn2" autocomplete="off"
                     v-model="form.noticeType" value="Urgent" />
              <label class="btn btn-outline-secondary" for="typeBtn2">{{ $t('common.urgent') }}</label>
            </div>
          </div>
          <div class="col-xl-4">
            <label class="form-label" for="notice-create-publish">
              <span>{{ $t('notice.isPublish') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <div class="btn-group w-100" role="group">
              <input type="radio" class="btn-check" id="isPublishBtn1" autocomplete="off"
                     v-model="form.isPublish" :value="true" />
              <label class="btn btn-outline-secondary" for="isPublishBtn1">{{ $t('common.open') }}</label>
              <input type="radio" class="btn-check" id="isPublishBtn2" autocomplete="off"
                     v-model="form.isPublish" :value="false" />
              <label class="btn btn-outline-secondary" for="isPublishBtn2">{{ $t('common.private') }}</label>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xl-12">
            <label class="form-label" for="notice-create-title">
              {{ $t('notice.title') }}
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <b-form-input class="form-control"
                          type="text"
                          id="notice-create-title"
                          v-model="form.title"
                          :class="{ 'is-invalid': submitted && $v.form.title.$error }" />
            <div v-if="submitted && $v.form.title.$error" class="invalid-feedback">
              <span v-if="!$v.form.title.required">{{ $t('validation.titleIsRequired') }}</span>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xl-12">
            <label class="form-label" for="notice-create-contents">{{ $t('notice.contents') }}</label>
            <ToastViewer id="notice-create-contents" ref="toastUiEditor" :contents="form.contents" />
          </div>
        </div>
        <div class="row mb-3" v-if="form.system.value !== 'APP'">
          <div class="col-xl-12">
            <label class="form-label" for="notice-create-attachment-file">{{ $t('common.attachment') }}</label>
            <div class="d-flex">
              <input ref="noticeCreateAttachFile"
                     type="file"
                     class="form-control"
                     id="notice-create-attachment-file"
                     @change="handleFileChange" />
              <button v-if="form.file" type="button" class="btn btn-outline-danger" @click="fileDel">DELETE</button>
            </div>
          </div>
        </div>
        <template v-if="form.noticeType === 'Urgent'">
          <div class="row mb-3">
            <div class="col-xl-6">
              <label class="form-label" for="notice-create-start">
                {{ $t('notice.showPeriod') }} {{ $t('notice.showPeriodSub') }}
                <i class="uil uil-asterisk text-danger ms-1"></i>
              </label>
              <div>
                <date-picker v-model="form.noticePeriod" type="datetime" range
                             class="w-100"
                             :placeholder="$t('alert.selectDateRange')"
                             :class="{ 'is-invalid': submitted && $v.form.noticePeriod.$error }" />
                <div v-if="submitted && $v.form.noticePeriod.$error" class="invalid-feedback">
                  <span v-if="!$v.form.noticePeriod.required">{{ $t('validation.periodIsRequired') }}</span>
                </div>
              </div>
            </div>
            <div class="col-xl-6"></div>
          </div>
        </template>

      </div>
    </div>

    <template #modal-footer>
      <div class="w-100 text-end">
        <b-button variant="secondary" class="w-xs p-1 me-3" @click="close">{{ $t('btn.close') }}</b-button>
        <b-button variant="success" class="w-xs p-1" @click="save">{{ $t('btn.save') }}</b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
import { http } from '@/auth-api';
import { required } from 'vuelidate/lib/validators';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';
import ToastViewer from '@/views/components/ToastViewer.vue';

export default {
  components: {
    Multiselect, ToastViewer
  },
  data() {
    return {
      visible: false,
      submitted: false,
      systemTypeOptions: [
        { text: 'APP', value: 'APP' },
        { text: 'CSMS', value: 'CSMS' },
      ],
      form: {
        title: null,
        contents: null,
        isPublish: false,
        contentType: 'notice',
        system: { text: 'APP', value: 'APP' },
        noticeType: 'General',
        file: null,
        noticePeriod: null
      }
    }
  },
  validations() {
    const isUrgent = this.form.noticeType === 'Urgent';

    return {
      form: {
        title: { required },
        noticePeriod: { required: isUrgent ? required : false }
      }
    }
  },
  methods: {
    show() {
      const vm = this;

      vm.visible = true;
    },
    close() {
      const vm = this;

      vm.resetForm();
      this.$emit('callback');
      vm.visible = false;
    },
    resetForm() {
      const vm = this;

      vm.form = {
        title: null,
        contents: null,
        isPublish: false,
        contentType: 'notice',
        system: { text: 'APP', value: 'APP' },
        noticeType: 'General',
        file: null,
        noticePeriod: null
      };

      vm.$nextTick(() => {
        this.$v.$reset();
      });
    },
    handleFileChange(e) {
      const vm = this;
      const _file = e.target.files[0];

      vm.form = { ...vm.form, file: _file };
    },
    fileDel() {
      const vm = this;

      vm.form = { ...vm.form, file: null };
      this.$refs.noticeCreateAttachFile.value = null;
    },
    save() {
      const vm = this;
      vm.submitted = true;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        Swal.fire({
          title: i18n.t('alert.confirmCreate'),
          showCancelButton: true,
          confirmButtonText: i18n.t('btn.ok'),
          cancelButtonText: i18n.t('btn.cancel')
        }).then((result) => {
          if (result.isConfirmed) {
            vm.saveItems();
          }
        });
      }
    },
    saveItems() {
      const vm = this;
      const _url = '/onmapi/view/Notice/create';
      const _html = this.$refs.toastUiEditor.getHTML();

      const _formData = new FormData();

      _formData.append('title', vm.form.title);
      _formData.append('contents', _html);
      _formData.append('isPublish', vm.form.isPublish);
      _formData.append('contentType', vm.form.contentType);
      _formData.append('appCsmsType', vm.form.system.value);
      _formData.append('noticeType', vm.form.noticeType);

      if (!this.$_utils.isEmpty(vm.form.file) && vm.form.system.value !== 'APP') {
        _formData.append('file', vm.form.file);
      }

      if (vm.form.noticeType === 'Urgent' && !this.$_utils.isEmpty(vm.form.noticePeriod)) {
        _formData.append('noticeStart', this.$moment(vm.form.noticePeriod[0]).format('YYYY-MM-DD HH:mm:ss'));
        _formData.append('noticeEnd', this.$moment(vm.form.noticePeriod[1]).format('YYYY-MM-DD HH:mm:ss'));
      }

      http.post(_url, _formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        if (res) {
          Swal.fire({
            icon: 'info',
            title: i18n.t('alert.created'),
            confirmButtonText: i18n.t('btn.close')
          });
          vm.close();
        }
      }).catch(err => {
        console.error('Notice Create Error :: ', err)

        Swal.fire({
          icon: 'error',
          title: i18n.t('alert.error'),
          confirmButtonText: i18n.t('btn.close')
        });
      });
    }
  }
}
</script>
