<template>
  <b-modal header-class="ion-bg-modal-header" size="xl"
           centered scrollable no-close-on-backdrop
           v-model="visible">

    <template #modal-header>
      <div class="fw-bold">{{ $t('notice.noticeTitle') }} {{ $t('common.edit') }}</div>
    </template>

    <div class="row">
      <div class="col-xl-12">

        <div class="row mb-3">
          <div class="col-xl-4">
            <label class="form-label" for="notice-update-system-type">
              <span>{{ $t('notice.appCsmsType') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <multiselect id="notice-update-system-type"
                         v-model="form.system"
                         label="text" track-by="value"
                         :options="systemTypeOptions"
                         selectLabel=""
                         selectedLabel=""
                         deselectLabel=""
                         :searchable="false" :allow-empty="false" />
          </div>
          <div class="col-xl-4">
            <label class="form-label" for="notice-update-type">
              <span>{{ $t('notice.noticeType') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <div class="btn-group w-100" role="group">
              <input type="radio" class="btn-check" id="typeBtn1" autocomplete="off"
                     v-model="form.noticeType" value="General" />
              <label class="btn btn-outline-secondary" for="typeBtn1">{{ $t('common.general') }}</label>
              <input type="radio" class="btn-check" id="typeBtn2" autocomplete="off"
                     v-model="form.noticeType" value="Urgent" />
              <label class="btn btn-outline-secondary" for="typeBtn2">{{ $t('common.urgent') }}</label>
            </div>
          </div>
          <div class="col-xl-4">
            <label class="form-label" for="notice-update-publish">
              <span>{{ $t('notice.isPublish') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <div class="btn-group w-100" role="group">
              <input type="radio" class="btn-check" id="isPublishBtn1" autocomplete="off"
                     v-model="form.isPublish" :value="true" />
              <label class="btn btn-outline-secondary" for="isPublishBtn1">{{ $t('common.open') }}</label>
              <input type="radio" class="btn-check" id="isPublishBtn2" autocomplete="off"
                     v-model="form.isPublish" :value="false" />
              <label class="btn btn-outline-secondary" for="isPublishBtn2">{{ $t('common.private') }}</label>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xl-12">
            <label class="form-label" for="notice-update-title">
              {{ $t('notice.title') }}
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <b-form-input class="form-control"
                          type="text"
                          id="notice-update-title"
                          v-model="form.title"
                          :class="{ 'is-invalid': submitted && $v.form.title.$error }" />
            <div v-if="submitted && $v.form.title.$error" class="invalid-feedback">
              <span v-if="!$v.form.title.required">{{ $t('validation.titleIsRequired') }}</span>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xl-12">
            <label class="form-label" for="notice-update-contents">{{ $t('notice.contents') }}</label>
            <ToastViewer v-if="!$_utils.isEmpty(form.contents)"
                         id="notice-update-contents" ref="toastUiEditor" :contents="form.contents" />
          </div>
        </div>
        <div class="row mb-3" v-if="form.system.value !== 'APP'">
          <div class="col-xl-12">
            <label class="form-label" for="notice-update-attachment-file">{{ $t('common.attachment') }}</label>
            <template v-if="savedFile">
              <div class="d-flex hstack gap-3">
                <input class="form-control me-auto" type="text" v-model="savedFile.orgName" disabled />
                <div class="vr"></div>
                <button type="button" class="btn btn-outline-danger" @click="savedFileDel">Delete</button>
              </div>
            </template>
            <template v-else>
              <div class="d-flex">
                <input ref="noticeUpdateAttachFile"
                       type="file"
                       class="form-control"
                       id="notice-update-attachment-file"
                       @change="handleFileChange" />
                <button v-if="file" type="button" class="btn btn-outline-danger" @click="fileDel">Delete</button>
              </div>
            </template>
          </div>
        </div>
        <template v-if="form.noticeType === 'Urgent'">
          <div class="row mb-3">
            <div class="col-xl-6">
              <label class="form-label" for="notice-update-start">
                {{ $t('notice.showPeriod') }} {{ $t('notice.showPeriodSub') }}
                <i class="uil uil-asterisk text-danger ms-1"></i>
              </label>
              <div>
                <date-picker v-model="form.noticePeriod" type="datetime" range
                             class="w-100"
                             :placeholder="$t('alert.selectDateRange')"
                             :class="{ 'is-invalid': submitted && $v.form.noticePeriod.$error }" />
                <div v-if="submitted && $v.form.noticePeriod.$error" class="invalid-feedback">
                  <span v-if="!$v.form.noticePeriod.required">{{ $t('validation.periodIsRequired') }}</span>
                </div>
              </div>
            </div>
            <div class="col-xl-6"></div>
          </div>
        </template>

      </div>
    </div>

    <template #modal-footer>
      <div class="w-100 text-end">
        <b-button variant="secondary" class="w-xs p-1 me-3" @click="close">{{ $t('btn.close') }}</b-button>
        <b-button variant="success" class="w-xs p-1" @click="save">{{ $t('btn.save') }}</b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
import {http} from '@/auth-api';
import { required } from 'vuelidate/lib/validators';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';
import ToastViewer from '@/views/components/ToastViewer.vue';

export default {
  components: {
    Multiselect, ToastViewer
  },
  props: {
    selectedId: {
      type: Number
    }
  },
  data() {
    return {
      visible: false,
      submitted: false,
      systemTypeOptions: [
        { text: 'APP', value: 'APP' },
        { text: 'CSMS', value: 'CSMS' },
      ],
      form: {
        title: null,
        contents: null,
        isPublish: false,
        contentType: 'notice',
        system: { text: 'APP', value: 'APP' },
        noticeType: 'General',
        noticePeriod: null
      },
      noticePeriod: null,
      savedFile: null,
      file: null
    }
  },
  validations() {
    const isUrgent = this.form.noticeType === 'Urgent';

    return {
      form: {
        title: { required },
        noticePeriod: { required: isUrgent ? required : false }
      }
    }
  },
  methods: {
    show() {
      const vm = this;

      vm.getItem();
      vm.visible = true;
    },
    close() {
      const vm = this;

      vm.resetForm();
      this.$emit('callback');
      vm.visible = false;
    },
    resetForm() {
      const vm = this;

      vm.form = {
        title: null,
        contents: null,
        isPublish: false,
        contentType: 'notice',
        system: { text: 'APP', value: 'APP' },
        noticeType: 'General',
        noticePeriod: null
      };
      vm.savedFile = null;
      vm.file = null;

      vm.$nextTick(() => {
        this.$v.$reset();
      });
    },
    getItem() {
      const vm = this;
      const _url = `/onmapi/view/Notice/retrieve/${this.selectedId}`;

      http.get(_url).then((res) => {
        vm.item = res.data.item;
        vm.form = Object.assign({}, vm.item);

        if (res.data.item.appCsmsType) {
          vm.form = { ...vm.form, system: vm.systemTypeOptions.find(ele => ele.value === res.data.item.appCsmsType) };
        }

        if (vm.item.attachFile) {
          vm.savedFile = vm.item.attachFile;
        }

        if (vm.item.noticeType === 'Urgent') {
          vm.form = { ...vm.form, noticePeriod: [new Date(vm.item.noticeStart), new Date(vm.item.noticeEnd)] };
        }
      }).catch((err) => {
        console.error('Notice Retrieve Error !!', err)
      });
    },
    save() {
      const vm = this;
      vm.submitted = true;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        Swal.fire({
          title: i18n.t('alert.confirmEdit'),
          showCancelButton: true,
          confirmButtonText: i18n.t('btn.ok'),
          cancelButtonText: i18n.t('btn.cancel')
        }).then((result) => {
          if (result.isConfirmed) {
            vm.saveItems();
          }
        });
      }
    },
    saveItems() {
      const vm = this;
      const _url = '/onmapi/view/Notice/update';
      const _html = this.$refs.toastUiEditor.getHTML();

      const _formData = new FormData();

      _formData.append('noticeId', this.selectedId.toString());
      _formData.append('title', vm.form.title);
      _formData.append('contents', _html);
      _formData.append('isPublish', vm.form.isPublish);
      _formData.append('contentType', vm.form.contentType);
      _formData.append('appCsmsType', vm.form.system.value);
      _formData.append('noticeType', vm.form.noticeType);

      if (!this.$_utils.isEmpty(vm.file) && vm.form.system.value !== 'APP') {
        _formData.append('file', vm.file);
      }

      if (vm.form.noticeType === 'Urgent' && !this.$_utils.isEmpty(vm.form.noticePeriod)) {
        _formData.append('noticeStart', this.$moment(vm.form.noticePeriod[0]).format('YYYY-MM-DD HH:mm:ss'));
        _formData.append('noticeEnd', this.$moment(vm.form.noticePeriod[1]).format('YYYY-MM-DD HH:mm:ss'));
      }

      http.patch(_url, _formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        if (res) {
          Swal.fire({
            icon: 'info',
            title: i18n.t('alert.edited'),
            confirmButtonText: i18n.t('btn.close')
          });
          vm.close();
        }
      }).catch(err => {
        console.error('Notice Update Error :: ', err)

        Swal.fire({
          icon: 'error',
          title: i18n.t('alert.error'),
          confirmButtonText: i18n.t('btn.close')
        });
      });
    },
    fileDel() {
      const vm = this;

      vm.file = null;
      this.$refs.noticeUpdateAttachFile.value = null;
    },
    savedFileDel() {
      const vm = this;

      Swal.fire({
        title: i18n.t('alert.confirmDelete'),
        showCancelButton: true,
        confirmButtonText: i18n.t('btn.ok'),
        cancelButtonText: i18n.t('btn.cancel')
      }).then((result) => {
        if (result.isConfirmed) {
          vm.deleteImageFile();
        }
      });
    },
    deleteImageFile() {
      const vm = this;
      const _url = `/onmapi/view/Notice/delete/file/${this.selectedId}`;

      http.delete(_url).then(res => {
        vm.savedFile = null;
        vm.file = null;
        delete vm.form.attachFile;

        Swal.fire({
          icon: 'info',
          title: i18n.t('alert.deleted'),
          confirmButtonText: i18n.t('btn.close')
        });
      }).catch(err => {
        console.error('Notice Delete File Error :: ', err)

        Swal.fire({
          icon: 'error',
          title: i18n.t('alert.error'),
          confirmButtonText: i18n.t('btn.close')
        });
      });
    },
    handleFileChange(e) {
      const vm = this;
      vm.file = e.target.files[0];
    }
  }
}
</script>
